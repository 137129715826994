import { AppConfigData } from '../../core/config/appConfigData';

export const Templates = {
  UMD: {
    template_name: 'UtilityMeasuringDevices.xlsx',
    id: 'umd',
    url: AppConfigData.umdBulkUpload,
    requiredProjectId: true,
  },
  CONSUMER: {
    template_name: 'Consumers.xlsx',
    id: 'consumer',
    url: AppConfigData.consumerBulkUpload,
    requiredProjectId: true,
  },
  RESIDENTIALUNITS: {
    template_name: 'ResidentailUnits.xlsx',
    id: 'ResidentialUnits',
    url: AppConfigData.residentialBulkUpload,
    requiredProjectId: true,
  },
} as const;

export const table_pageSize = {
  pageSizes: [5, 10, 20, 50, 100, 200, 500, 750, 1000],
}

export const slop_position = [
  { id: 1, name: '1' },
  { id: 2, name: '2' },
  { id: 3, name: '3' },

]

export const title_List = [
  { id: '1', name: 'Mr' },
  { id: '2', name: 'Mrs' },
  { id: '3', name: 'Miss' },
  { id: '4', name: 'Dr' },

]

export const utilitiesArray = ['Water', 'Electricity', 'Gas']

export const colorsList = ['#FADF89', '#F9AF58', '#F37E53', '#E96360', '#DB6FA0'];

export const ConsumptioncolorsList = ['#E96360', '#DB6FA0', '#AD8AC0', '#6480BC', '#44C8F5'];

export const ConsumptionMinMax = [{ id: 1, name: 'Max' }, { id: 2, name: 'Min' }]

export const monthsArray = [
  'Current Month',
  'Last Month',
  'Last Three Months',
  'Last Six Months',
  'Custom Range',
];




